<template>
  <main class="main">
    <div class="container">
      <div class="main__inner" v-if="news">
        <BreadcrumbsComponent :title="news.title" :parentPages="parentPages" />
        <section class="section open section-pb48">
          <div class="open__info_row">
            <a class="category" v-if="news.category && news.category.title">
              {{ news.category.title }}
            </a>
            <div class="date" v-if="news.date_publication">
              <time :time="news.date_publication | robotDate">{{ news.date_publication | humanDateRu }}</time>
            </div>
          </div>
          <div class="open__row open__row-full">
            <div class="open__col-content">
              <div class="open__title" v-if="news.title">
                {{ news.title }}
              </div>
              <div v-if="news.headImg && news.headImg.img && news.headImg.img.url" class="open__img">
                <div class="img__container">
                  <img :src="news.headImg | image($store.state.api)" :alt="news.headImg | image_alt" />
                </div>
              </div>
              <EditorJSComponent :text="JSON.parse(news.description)" v-if="news.description" />
              <div v-if="news.join_documents && news.join_documents.length">
                <div class="open__attached_fails">Прикреплённые файлы:</div>
                <div class="open__files">
                  <div class="open__file" :key="index" v-for="(item, index) in news.join_documents">
                    <div class="open__file-main">
                      <img src="/static/images/document.png" alt="" />
                      <div class="open__file__info">
                        <a
                          :href="getDocumentUrl(item.document.url)"
                          download
                          target="_blank"
                          v-if="item.document.title"
                          class="open__file__info-title"
                        >{{ item.document.title }}
                        </a>
                        <div class="open__file__info-size">
                          {{ item.document.type }} <template v-if="item.document.size">({{ item.document.size }})</template>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </main>
</template>

<script>
import EditorJSComponent from 'components/EditorJSComponent.vue'
import BreadcrumbsComponent from 'components/BreadcrumbsComponent.vue'

export default {
  name: 'DepartmentNewsOpen',
  components: {
    EditorJSComponent,
    BreadcrumbsComponent
  },
  async asyncData ({ store, route, apollo }) {
    await store.dispatch('GET_NEWS_OPEN', {
      apollo: apollo,
      variables: {
        id: parseInt(route.params.id)
      }
    })
  },
  computed: {
    news () {
      return this.$store.state.news_open
    },
    parentPages () {
      if (this.news && this.news.type && this.news.type.code) {
        switch (this.news.type.code) {
          case this.$store.state.type.CODE_ACTIVITY:
            return [
              {
                title: 'Деятельность',
                name: 'activity'
              }
            ]
        }
      }
      return []
    }
  },
  methods: {
    getDocumentUrl (url) {
      return this.news.department_id ? this.$store.state.api + url : this.$store.state.main_api + url
    }
  },
  metaInfo () {
    return this.$seo(
      'common',
      'Официальный сайт Главы города Махачкалы',
      '',
      '',
      'Официальный сайт Главы города Махачкалы',
      '',
      ''
    )
  },
  jsonld () {
    return {
      '@context': 'https://schema.org',
      '@type': 'Organization'
    }
  }
}
</script>

<style lang="stylus">
@import "~@/styles/mixins/ratio.styl"
@import "~@/styles/parts/news_open.styl"
</style>
